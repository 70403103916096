(function () {
    $('.select-subject').on('click', function () {
        $('#selectSubjectModal').modal({show: true});
        return false;
    });

    $('.subject-selected').on('click', function () {
        $('#selectSubjectModal').modal({show: true});
        return false;
    });

    $('#selectSubjectModal .imageSelect').on('click', function() {
        $(this).toggleClass('selected');
        return false;
    });
})();
