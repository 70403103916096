/* globals $, dayjs, bootbox, bootstrap */

$(function () {

    const $dropdown = $('.country-drop-down');

    $dropdown.each(function(){
        const $button = $('<div class="button"></div>'),
            $selectList = $('<ul class="select-list"></ul>'),
            $select = $dropdown.find('select');

        $dropdown.append($button);
        $dropdown.append($selectList);

        /* Custom select design */
        $select.find('option').each(function () {
            const $option = $(this);
            $('.select-list').append('<li class="clsAnchor"><span value="' + $option.val() + '" class="' + $option.attr('class') + '" style=background-image:' + $option.css('background-image') + '>' + $option.text() + '</span></li>');
        });

        $button.html('<span style=background-image:' + $select.find(':selected').css('background-image') + '>' + $select.find(':selected').text() + '</span>' + '<a href="javascript:void(0);" class="select-list-link">Arrow</a>');

        $('.country-drop-down .select-list span').on('click', function () {
            const dd_text = $(this).text(),
                dd_img = $(this).css('background-image'),
                dd_val = $(this).attr('value');
            $button.html('<span style=background-image:' + dd_img + '>' + dd_text + '</span>' + '<a href="javascript:void(0);" class="select-list-link">Arrow</a>');
            $selectList.find('span').parent().removeClass('active');
            $select.find('[name=country]').val(dd_val);
            $selectList.toggle();
            $selectList.find('li').toggle();
            $(this).parent().addClass('active');
        });

        $button.on('click', 'a.select-list-link', function () {
            $dropdown.find('ul').toggle();
            $dropdown.find('ul li').toggle();
        });

        $dropdown.find('ul li').each(function () {
            if ($(this).find('span').text() === $('.drop-down select').find(':selected').text()) {
                $(this).addClass('active');
            }
        });

    });

});
