/* globals $, dayjs, bootbox, bootstrap, Bloodhound */

/**
 * Advanced search submit functions
 */
$(function () {

    $('form.search-form').each(function () {
        let searchForm = $(this),
            hasAnyValue = false,
            action = searchForm.attr('action');
        action = action ? action : '';

        const uiPosition = searchForm.find('input[name="ux"]').val()
        const urlParams = new URLSearchParams(window.location.search);

        if (!uiPosition || (parseInt(uiPosition) === parseInt(urlParams.get('ux')))) {
            searchForm.find('.search_field').each(function () {
                let field = $(this),
                    name = field.attr('name'),
                    values = urlParams.getAll(name);
                if (values !== undefined && values.length > 0) {
                    if (field.is('select') && field.attr('multiple') === 'multiple') {
                        field.find('option').each(function () {
                            let opt = $(this);
                            if (values.indexOf(opt.attr('value')) !== -1) {
                                opt.prop('selected', true);
                            }
                        });
                    } else {
                        field.val(values[0]);
                    }
                    hasAnyValue = true;
                }
            });
        }

        if (searchForm.find('input[name="q"]').val() !== '') {
            searchForm.find('.searchResult').show(); // TODO remove this
        }

        searchForm.on('keypress', '.search-input', function (e) {
            if (e.keyCode === 13) {
                searchForm.trigger('submit');
                return false;
            }
        });
        searchForm.on('keypress', 'input[name="q"]', function (e) {
            if (e.keyCode === 13) {
                searchForm.find('input[name="businessModelRights"]').val("");
                searchForm.attr('action', action)
                    .attr('target', '_self')
                    .trigger('submit');
                return false;
            }
        });
        searchForm.on('click', '.btn-reset', function () {
            searchForm.find('.search_field').val('');
            searchForm.attr('action', action)
                .attr('target', '_self')
                .trigger('submit');
            return false;
        });
        searchForm.on('click', '.btn-search', function () {
            searchForm.find('input[name="businessModelRights"]').val("");// TODO remove this?
            searchForm.attr('action', action)
                .attr('target', '_self')
                .trigger('submit');
            return false;
        });
        searchForm.on('submit', function () {
            // Disables any input field that is empty, so that we dont send info in URL
            searchForm.attr('target', '_self');
            searchForm.find('.search_field').each((i, a) => {
                let input = $(a), value = input.val();
                if (value === '' || value === null || value === undefined) {
                    input.attr('disabled', 'disabled');
                }
            })
            return true;
        });

        // TODO remove this?
        $('#exportForm').one('submit', function () {
            let exportForm = $(this);
            searchForm.find('input[name="currency"]').val(exportForm.find('input#exportCurrency').val());
            searchForm.find('input[name="businessModelRights"]').val(
                exportForm.find('input[name="businessModelRights"]:checked')
                    .map(function () { return $(this).val() })
                    .toArray()
                    .join(',')
            );
            searchForm.attr('action', window.location.pathname + '/export');
            searchForm.attr('target', '_self');
            searchForm.trigger('submit');
            return false;
        });

        $('#sendPushNotificationForm').one('submit', function () {
            const pushNotificationForm = $(this);
            const title = searchForm.find('input[name="notificationTitle"]').val(pushNotificationForm.find('input[name="notificationTitle"]').val());
            const body = searchForm.find('input[name="notificationBody"]').val(pushNotificationForm.find('textarea[name="notificationBody"]').val());
            if (title.length <= 0) {
                bootbox.alert("Please enter a title.");
            }
            if (body.length <= 0) {
                bootbox.alert("Please enter a body.");
            } else {
                bootbox.confirm({
                    message: "Are you sure you want to send this push notification?",
                    buttons: {
                        confirm: {
                            label: 'Yes',
                            className: 'btn-outline-snapplify'
                        },
                        cancel: {
                            label: 'No',
                            className: 'btn-outline-secondary'
                        }
                    },
                    callback: function (res) {
                        if (res) {
                            searchForm.attr('action', window.location.pathname + '/sendPushNotification');
                            searchForm.attr('target', '_self');
                            searchForm.trigger('submit');
                            return false;
                        }
                    }
                });
            }
            return false;
        });
    });
});
