/* globals $, dayjs, bootbox, bootstrap, Bloodhound */
import './common'

import './js/tagmanager'
import './js/country.dropdown'
import './js/customer.search'
import './js/select.subject'
import './js/advanced.search'

import './scss/web.scss'

// Some generic UI methods, validation, table onclick
(function () {
    $(function () {
        $('table').each(function () {
            const tbl = $(this);
            tbl.on('click', 'input[type="checkbox"].select-all', function () {
                const checked = $(this).prop('checked');
                const tbl = $(this).closest('table');
                tbl.find('tbody input[type="checkbox"]').each(function () {
                    $(this).prop('checked', checked);
                });
            });
        });
        $('.collapse-head').each(function () {
            const header = $(this),
                bdy = header.next('.collapse-body');
            header.on('click', function () {
                bdy.toggleClass('collapse-hide');
                header.toggleClass('collapse-hide')
            })
        })
        $('span.health-error').tooltip();
    });
})();


