/* globals $, dayjs, bootbox, bootstrap */

(function () {

    function loadImg(url, delay, callback) {
        const time = (delay === '1') ? 2500 : 0;
        setTimeout(function () {
            const img = new Image();
            img.onload = function () {
                callback(img)
            };
            img.onerror = function () {};
            img.src = url;
        }, time);
    }

    window.lazyLoadImages = function () {
        [].forEach.call(document.querySelectorAll('img[data-src]'), function (img) {
            if (!img.classList.contains('loaded')) {
                const datasrc = img.getAttribute('data-src'), delay = img.getAttribute('data-delay');
                if (datasrc) {
                    loadImg(datasrc, delay, function () {
                        $(img).addClass('loaded').attr('src', datasrc);
                    });
                }
            }
        });
        [].forEach.call(document.querySelectorAll('div[data-preload]'), function (img) {
            if (!img.classList.contains('loaded')) {
                const datasrc = img.getAttribute('data-preload'), delay = img.getAttribute('data-delay');
                if (datasrc) {
                    loadImg(datasrc, delay, function () {
                        $(img).addClass('loaded').attr('style', 'background-image: url("' + datasrc + '");');
                    });
                }
            }
        });
    };
})();
