/**
 * This file is used for sites that don't need all in app.js
 * But this file is included to invoke exposure of certain libraries.
 * 'expose-loader', basically puts the variables on the window scope
 * We need this so that scripts in the projects, can access these - its bad, should be refactored out.
 */
import 'expose-loader?exposes=$,jQuery!jquery';
import 'expose-loader?exposes=bootstrap!bootstrap';
import 'expose-loader?exposes=bloodhound,Bloodhound!bloodhound-js';
import 'expose-loader?exposes=bootbox!bootbox';
import 'expose-loader?exposes=dayjs!dayjs';

import 'tether'
import 'corejs-typeahead'
import 'jquery-validation'
import 'jquery-validation/dist/additional-methods.min'

import 'bootstrap-datepicker'
import 'bootstrap-fileinput'

/* global dayjs */
// @CLEANUP not sure about this
dayjs.extend(require('dayjs/plugin/relativeTime'))

import './js/debounce'
import './js/throttle'
import './js/lazyload'

