import $ from "jquery";

/*
    Engage Customer Search
*/
$(function () {

    $('form.customer-search .search_box').on('click', function () {
        var dropdownMenu = $(this).parent().children(".customer-dropdown");
        dropdownMenu.toggleClass("show");
    });
});
